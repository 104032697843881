<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="find-form">
      <div class="find-form-header flex flex-row items-center justify-between">
        <h3 v-if="step < 3">비밀번호찾기</h3>
        <h3 v-else>비밀번호변경</h3>
      </div>

      <div class="find-form-content">
        <p v-if="step == 1" class="content-title">
          가입하신 이메일로 인증코드를 보내드립니다.
        </p>

        <div class="find-step-2" v-if="step == 1">
          <div class="find-input-form flex flex-col">
            <label>이메일</label>
            <input
              v-model="email"
              type="email"
              placeholder="이메일을 입력해주세요"
              @keyup.enter="onGoToStep2"
            />
          </div>

          <button
            v-on:click="onGoToStep2"
            class="w-full flex justify-center items-center"
            v-bind:class="{ inactive: !email }"
          >
            다음
          </button>
        </div>

        <div class="find-step-3" v-if="step == 2">
          <div class="find-input-form flex flex-col">
            <label>인증코드</label>
            <input
              v-model="code"
              type="text"
              placeholder="인증코드를 입력해주세요"
              @keyup.enter="onGoToStep3"
            />
          </div>

          <button
            v-on:click="onGoToStep3"
            class="w-full flex justify-center items-center"
            v-bind:class="{ inactive: !code }"
          >
            다음
          </button>
        </div>

        <div class="find-step-3" v-if="step == 3">
          <div class="find-input-form flex flex-col">
            <label>비밀번호</label>
            <input
              v-model="password"
              type="password"
              placeholder="8자리 이상 영문, 숫자, 특수문자 포함"
            />
          </div>

          <div class="find-input-form flex flex-col">
            <label>비밀번호 확인</label>
            <input
              v-model="password2"
              type="password"
              placeholder="비밀번호 확인"
            />
          </div>

          <button
            v-on:click="onSubmit"
            class="w-full flex justify-center items-center"
            v-bind:class="{ inactive: !password || !password2 }"
          >
            비밀번호 변경
          </button>
        </div>

        <div class="find-step-4" v-if="step == 4">
          <div class="flex flex-col items-center">
            <img src="@/assets/signup-complete.png" />
            <p>
              비밀번호 변경이 완료되었습니다.<br />
              지금 바로 비유 서비스를 이용해 주세요.
            </p>
          </div>

          <button
            v-on:click="goToLogin"
            class="w-full flex justify-center items-center"
          >
            서비스 시작하기
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import services from "@/services";

export default {
  name: "FindPassword",
  data() {
    return {
      step: 1,
      email: "",
      code: "",
      password: "",
      password2: "",
    };
  },
  methods: {
    async onGoToStep2() {
      try {
        const success = await services.verify({ email: this.email });

        if (success) {
          this.$toast.open("인증코드 발송완료");
          this.step = 2;
        } else {
          this.$toast.open({ message: "인증코드 발송실패", type: "error" });
        }
      } catch (error) {
        console.log('status code : ' + error.response.status);

        if (error.response.status == 412) {
          try {
            const messages = JSON.parse(error.response.data.message);
            const firstKey = Object.keys(messages)[0];
            const firstMessage = messages[firstKey];
            const firstValue = firstMessage[0];

            this.$toast.open({ message: firstValue, type: "error" });
          } catch (error) {
            console.log('Json parse error');
            console.log(error);
            this.$toast.open({ message: "오류가 발생했습니다.", type: "error" });
          }
        } else if (error.response.status == 424) {
          try {
            const errorMessage = error.response.data.message;

            this.$toast.open({ message: errorMessage, type: "error" });
          } catch (error) {
            console.log('Json parse error');
            console.log(error);
            this.$toast.open({ message: "오류가 발생했습니다.", type: "error" });
          }
        } else {
          console.log(error);
          this.$toast.open({ message: "오류가 발생했습니다.", type: "error" });
        }
      }
    },
    async onGoToStep3() {
      const success = await services.verifyCheck({
        email: this.email,
        verifyNum: this.code,
      });

      if (success) {
        this.$toast.open("인증코드 완료");
        this.step = 3;
      } else {
        this.$toast.open({ message: "인증코드 실패", type: "error" });
      }
    },
    async onSubmit() {
      const data = await services.userList({ email: this.email });
      if (data.length === 0) {
        this.$toast.open({
          message: "존재하지 않는 이메일입니다.",
          type: "error",
        });
        return;
      }

      const userId = data[0].id;
      const success = await services.userUpdate(userId, {
        password: this.password,
        passwordConfirmation: this.password2,
      });
      if (success) {
        this.$toast.open("비밀번호 변경 완료");
        this.step = 4;
      } else {
        this.$toast.open({ message: "비밀번호 변경 실패", type: "error" });
      }
    },
    goToLogin: function() {
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.find-form {
  margin-top: 225px;
  margin-bottom: 130px;
  border: 1px solid #d7d7d7;
  width: 588px;
  padding-top: 74px;
  padding-bottom: 45px;

  .find-form-header {
    margin-left: 65px;
    margin-right: 65px;
    padding-bottom: 35px;
    border-bottom: 1px solid #dddee1;

    h3 {
      font-size: 30px;
      font-weight: 700;
      color: #333333;
      letter-spacing: -1.2px;
    }
  }

  .find-form-content {
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 38px;

    .content-title {
      color: #333;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.56px;
    }

    .divider {
      margin-top: 24px;
      margin-bottom: 22px;
      color: #eee;
    }
  }

  .find-step-1 {
    margin-top: 55px;

    .agree-item {
      margin-bottom: 22px;

      img {
        cursor: pointer;
      }
    }

    p {
      font-size: 13px;
      color: #898989;
      letter-spacing: -0.52px;
      font-weight: 500;

      a {
        color: #293bf0;
      }
    }

    button {
      margin-top: 34px;
      height: 55px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      background-color: #283aef;

      &.inactive {
        background-color: #dfdfdf;
      }
    }
  }

  .find-step-2 {
    margin-top: 50px;

    .find-input-form-group {
      span {
        margin-right: 15px;
        margin-left: 15px;
      }
    }

    .find-input-form {
      margin-bottom: 22px;

      label {
        color: #555555;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.56px;
      }

      input {
        color: #333333;
        height: 40px;
        border-bottom: 1px solid #d7d7d7;
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.14px;
        padding-left: 9px;
        padding-right: 9px;

        &::placeholder {
          color: #c1c1c1;
        }
      }
    }

    button {
      margin-top: 36px;
      height: 55px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      background-color: #283aef;

      &.inactive {
        background-color: #dfdfdf;
      }
    }
  }

  .find-step-3 {
    margin-top: 50px;

    .find-input-form {
      margin-bottom: 17px;

      label {
        color: #555555;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.56px;
      }

      input {
        color: #333333;
        height: 40px;
        border-bottom: 1px solid #d7d7d7;
        padding-top: 12px;
        padding-bottom: 12px;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: -0.14px;
        padding-left: 9px;
        padding-right: 9px;

        &::placeholder {
          color: #c1c1c1;
        }
      }
    }

    button {
      margin-top: 36px;
      height: 55px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      background-color: #283aef;

      &.inactive {
        background-color: #dfdfdf;
      }
    }
  }

  .find-step-4 {
    padding-top: 85px;

    p {
      margin-top: 36px;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.32;
      letter-spacing: -0.56px;
      text-align: center;
      color: #333;
    }

    button {
      margin-top: 63px;
      height: 55px;
      color: white;
      font-size: 14px;
      font-weight: 700;
      background-color: #283aef;
    }
  }
}

@media (max-width: 639px) {
  .find-form {
    margin-top: 100px;
    margin-bottom: 50px;
    border: none;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    .find-form-header {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 20px;
      h3 {
        font-size: 20px;
      }
      span {
        font-size: 11px;
      }
      a {
        font-size: 11px;
      }
    }
    .find-form-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 25px;
      .content-title {
        font-size: 12px;
      }
      .find-input-form {
        label {
          font-size: 12px;
        }
        input {
          height: 35px;
          font-size: 12px;
        }
      }
      button {
        height: 40px;
        font-size: 13px;
      }
    }
  }
}
</style>
